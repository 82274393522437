import React from 'react'
import PageApi from '../components/pages/Api/Api'
import Layout from '../Layouts/zh'

const apiZh = ({ location }) => {
  return (
    <Layout location={location}>
      <PageApi />
    </Layout>
  )
}

export default apiZh
